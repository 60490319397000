export default {
  "item": "cmB",
  "details": "cmE",
  "flex": "cmd",
  "header": "cmI cmd",
  "flex-no-gap": "cmt cmd",
  "file-name": "cmM",
  "label": "cmP body-2",
  "self-icon": "cml",
  "with-separator": "cmX",
  "dimmed": "cmC"
};
