export default {
  "container": "cmO",
  "content": "cmm",
  "input-row": "cmp",
  "email-row": "cmx",
  "button-row": "cmw",
  "btn-edit": "cmN",
  "input-column": "cmv",
  "form-btn-row": "cmo",
  "border-bottom": "cmn",
  "avatar-input": "cmi"
};
