export default {
  "invoice-sidebar": "cHe",
  "body": "cHB",
  "box": "cHE",
  "box-header": "cHd",
  "footer": "cHI",
  "footer-button": "cHt",
  "footer-button-tooltip": "cHM",
  "reduced-fontsize": "cHP",
  "sidebar-box": "cHl",
  "border-top": "cHX",
  "row": "cHC",
  "status-container": "cHk",
  "due-date-container": "cHJ",
  "greyed-out": "cHs",
  "struck-through": "cHF",
  "overdue": "cHg",
  "primary-actions": "cHD cHl",
  "primary-action": "cHV",
  "danger-action": "cHL",
  "button-icon": "cHT",
  "button-label": "cHz body-2",
  "related-credit-note-title": "cHa caption-bold",
  "paid-at-placeholder": "cHH",
  "collapsible-timeline": "cHO",
  "timeline-status-label": "cHm",
  "related-documents": "cHp"
};
