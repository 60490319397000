export default {
  "referral-invite": "cxc",
  "referral": "cxq",
  "illustration": "cxZ",
  "steps": "cxR",
  "step": "cxQ",
  "share-referral": "cxe",
  "choice": "cxB",
  "line": "cxE",
  "email": "cxd",
  "email-input": "cxI",
  "input-field--error": "cxt",
  "error-message": "cxM",
  "external-link-icon": "cxP"
};
