export default {
  "mileage-validation": "chy",
  "actions": "chU",
  "action-btn": "chj",
  "approve-btn": "chW",
  "request-mileage-validation": "chu",
  "account-selector": "cfS",
  "has-error": "cfc",
  "funds-disclaimer": "cfq",
  "account-options": "cfZ",
  "account-balance": "cfR",
  "warnings": "cfQ"
};
