export default {
  "wrapper": "crh",
  "header": "crf mb-48",
  "form-title": "crK title-2 mb-16",
  "form-subtitle": "crG body-2",
  "form": "crr",
  "section": "crb mb-48",
  "section-title": "cry title-3 mb-16",
  "form-footer": "crU",
  "footer-btn": "crj",
  "transfer-form": "crW"
};
