export default {
  "request-transfer-validation": "cbq",
  "account-selector": "cbZ",
  "has-error": "cbR",
  "actions": "cbQ",
  "action-btn": "cbe",
  "warnings": "cbB",
  "top-border": "cbE",
  "account-selector-subtitle": "cbd",
  "action-btn-right": "cbI",
  "funds-disclaimer": "cbt",
  "account-options": "cbM",
  "account-balance": "cbP",
  "request-multi-transfer-validation": "cbl",
  "wrapper": "cbX",
  "warning-message-row": "cbC",
  "multi-transfer-align": "cbk",
  "button-approve": "cbJ",
  "button-approve-spinner": "cbs",
  "button-decline": "cbF"
};
