export default {
  "section-container": "chI",
  "disclaimer-section": "cht",
  "trip-title": "chM caption-bold mb-16",
  "google-link-container": "chP",
  "link-icon": "chl",
  "trip-details": "chX body-2",
  "trip-details-icon": "chC",
  "trip-details-title": "chk",
  "subtitle": "chJ caption-bold mb-16",
  "disclaimer": "chs",
  "visualizer": "chF"
};
