export default {
  "container": "cOH",
  "main": "cOO",
  "preview": "cOm",
  "form": "cOp",
  "scrollable": "cOx",
  "header": "cOw title-1",
  "form-sections": "cON",
  "form-section": "cOv",
  "footer": "cOo",
  "preview-header": "cOn",
  "centered": "cOi",
  "preview-content-wrapper": "cOA",
  "preview-content": "cOY",
  "payment-link-preview": "cOh"
};
