export default {
  "section-container": "cwk",
  "disclaimer-section": "cwJ",
  "trip-title": "cws caption-bold mb-16",
  "google-link-container": "cwF",
  "link-icon": "cwg",
  "trip-details": "cwD body-2",
  "trip-details-icon": "cwV",
  "trip-details-title": "cwL",
  "subtitle": "cwT caption-bold mb-16",
  "disclaimer": "cwz",
  "visualizer": "cwa"
};
