export default {
  "row": "cxA",
  "active": "cxY",
  "animated": "cxh",
  "fadein-item": "cxf",
  "cell": "cxK",
  "cell-content": "cxG",
  "row-sidebar": "cxr",
  "empty": "cxb",
  "cell-amount": "cxy",
  "subtitle": "cxU caption",
  "hidden": "cxj",
  "cell-approve": "cxW",
  "quick-actions": "cxu",
  "account-select": "cwS",
  "note": "cwc",
  "cell-content-amount": "cwq body-1",
  "cell-status": "cwZ"
};
