/* import __COLOCATED_TEMPLATE__ from './credit-note-modal.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { Spinner } from '@repo/design-system-kit';
import { PdfPreview } from '@repo/domain-kit/invoices';

import { Placeholder } from 'qonto/react/components/receivable-invoices/credit-note-modal/sidebar/placeholder';

// @ts-expect-error
import styles from './credit-note-modal.module.css';

interface ReceivableInvoicesCreditNoteModalSignature {
  // The arguments accepted by the component
  Args: {
    isLoading?: boolean;
  };
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class ReceivableInvoicesCreditNoteModalComponent extends Component<ReceivableInvoicesCreditNoteModalSignature> {
  pdfPreviewComponent = PdfPreview;
  placeholder = Placeholder;
  spinner = Spinner;

  styles = styles;

  @service declare router: Services['router'];
  @service declare organizationManager: Services['organizationManager'];
  @service declare intl: Services['intl'];
  @service declare segment: Services['segment'];

  get title() {
    return this.intl.t('receivable-invoices.credit-note-modal.credit-note-title.manual', {
      creditNoteNumber: this.creditNote?.number,
    });
  }

  get creditNote() {
    // @ts-expect-error
    return this.args.data.fetchTask.lastSuccessful?.value.creditNote;
  }

  get receivableInvoice() {
    // @ts-expect-error
    return this.args.data.fetchTask.lastSuccessful?.value.receivableInvoice;
  }

  get documentVariant() {
    return this.organizationManager.organization.legalCountry;
  }

  // Ember - React compatibility getter, forces Ember to create a new ref for the prop on mutation updates
  get document() {
    // @ts-expect-error
    let items = this.creditNote.items?.map(item => ({
      id: item.id,
      title: item.title,
      description: item.description,
      quantity: item.quantity,
      unit: item.unit,
      unitPrice: item.unitPrice,
      vatRate: item.vatRate,
      totalExcludingVat: item.totalExcludingVat,
      discount: item.discount,
      totalDiscount: item.totalDiscount,
      links: item.links,
      subTotal: item.subTotal,
      totalAmount: item.totalAmount,
      totalVat: item.totalVat,
      vatExemptionCode: item.vatExemptionCode,
    }));

    return {
      number: this.creditNote.number,
      status: this.creditNote.status,
      currency: this.creditNote.currency,
      isDeposit: this.creditNote.isDeposit,
      reason: this.creditNote.reason,
      bic: this.creditNote.bic,
      iban: this.creditNote.iban,
      reference: this.creditNote.reference,
      beneficiaryName: this.creditNote.beneficiaryName,
      contactEmail: this.creditNote.contactEmail,
      issueDate: this.creditNote.issueDate,
      performanceStartDate: this.creditNote.performanceStartDate,
      performanceEndDate: this.creditNote.performanceEndDate,
      expiryDate: this.creditNote.expiryDate,
      dueDate: this.creditNote.dueDate,
      depositInvoiceNumber: this.creditNote.depositInvoiceNumber,
      depositPaymentDate: this.creditNote.depositPaymentDate,
      purchaseOrder: this.creditNote.purchaseOrder,
      items,
      discount: this.creditNote.discount,
      totalExcludingVat: this.creditNote.totalExcludingVat,
      totalDiscount: this.creditNote.totalDiscount,
      discountedTotalExcludingVat: this.creditNote.discountedTotalExcludingVat,
      welfareFundAmount: this.creditNote.welfareFundAmount,
      displayEachVatSubtotals: this.creditNote.displayEachVatSubtotals,
      vatSubtotals: this.creditNote.vatSubtotals,
      totalVat: this.creditNote.totalVat,
      withholdingTax: this.creditNote.withholdingTax,
      withholdingTaxAmount: this.creditNote.withholdingTaxAmount,
      stampDutyAmount: this.creditNote.stampDutyAmount,
      totalAmount: this.creditNote.totalAmount,
      depositAmount: this.creditNote.depositAmount,
      totalAmountDue: this.creditNote.totalAmountDue,
      header: this.creditNote.header,
      footer: this.creditNote.footer,
      termsAndConditions: this.creditNote.termsAndConditions,
      pageCount: this.creditNote.pageCount,
      locale: this.creditNote.locale ?? this.receivableInvoice?.get?.('customer.locale'),
    };
  }

  get client() {
    let customer = this.receivableInvoice.get('customer');

    return customer
      ? {
          name: customer.get('name'),
          email: customer.get('email'),
          billingAddress: customer.get('billingAddress'),
          deliveryAddress: customer.get('deliveryAddress'),
          tinNumber: customer.get('tinNumber'),
          vatNumber: customer.get('vatNumber'),
          locale: customer.get('locale'),
        }
      : undefined;
  }

  get merchant() {
    let address = this.organizationManager.organization.address;
    let organizationStreetAddress = address.firstLine
      ? address.firstLine
          .concat(address.secondLine ? ` ${address.secondLine}` : '')
          .concat(address.thirdLine ? ` ${address.thirdLine}` : '')
      : '';

    return {
      legalName: this.organizationManager.organization.legalName,
      legalNumber: this.organizationManager.organization.legalNumber,
      legalCountry: this.organizationManager.organization.legalCountry,
      address: {
        streetAddress: organizationStreetAddress,
        zipCode: address.zipcode,
        city: address.city,
        countryCode: address.country,
      },
      taxNumber: this.organizationManager.organization.taxNumber,
      vatNumber: this.organizationManager.organization.vatNumber,
      contactEmail: this.organizationManager.organization.contactEmail,
      districtCourt: this.organizationManager.organization.districtCourt,
      commercialRegisterNumber: this.organizationManager.organization.commercialRegisterNumber,
      companyLeadership: this.organizationManager.organization.companyLeadership,
      shortLegalForm: this.organizationManager.organization.shortLegalForm,
      locale: this.organizationManager.organization.locale,
    };
  }

  get relatedInvoice() {
    let depositInvoice =
      this.receivableInvoice.get?.('depositInvoice') ?? this.receivableInvoice.depositInvoice;

    return this.receivableInvoice
      ? {
          number: this.receivableInvoice.number,
          issueDate: this.receivableInvoice.issueDate,
          purchaseOrder: this.receivableInvoice.purchaseOrder,
          depositInvoice: depositInvoice
            ? {
                number: depositInvoice.number,
                issueDate: depositInvoice.issueDate,
                paidAt: depositInvoice.paidAt,
              }
            : undefined,
        }
      : undefined;
  }

  get logo() {
    let { isDefaultAvatar, picture } = this.organizationManager.organization;
    return isDefaultAvatar ? null : picture;
  }

  get isPdfPreviewWithNoToolbar() {
    // @ts-expect-error
    return this.creditNote?.pdfPreviewIframeUrl && this.args.data.isPdfPreviewWithNoToolbar;
  }

  @action goBack() {
    this.segment.track('credit_note_options_escape');
    this.router.transitionTo('receivable-invoices.index');
  }

  // @ts-expect-error
  @action handleEscape(e) {
    if (e.key === 'Escape') {
      this.goBack();
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'ReceivableInvoices::CreditNoteModal': typeof ReceivableInvoicesCreditNoteModalComponent;
  }
}
