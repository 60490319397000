export default {
  "attachment-viewer": "cYc",
  "hide-sidebar": "cYq",
  "sidebar": "cYZ",
  "header": "cYR",
  "header-top": "cYQ",
  "header-icon": "cYe",
  "header-amount": "cYB",
  "infobox": "cYE",
  "vat": "cYd",
  "validation": "cYI"
};
