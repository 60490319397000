export default {
  "row": "cAs",
  "empty": "cAF",
  "active": "cAg",
  "animated": "cAD",
  "fadein-item": "cAV",
  "cell": "cAL body-2",
  "cell-content": "cAT",
  "cell-amount": "cAz",
  "cell-content-amount": "cAa body-1"
};
