export default {
  "qonto-pilot-modal": "cVh",
  "content": "cVf",
  "header": "cVK",
  "transition-state-container": "cVG",
  "lottie": "cVr",
  "prompt-input": "cVb",
  "buttons": "cVy",
  "footer": "cVU caption",
  "divider": "cVj",
  "illustration": "cVW",
  "icon": "cVu",
  "quick-actions-container": "cLS",
  "action": "cLc body-2",
  "action-title": "cLq",
  "disabled": "cLZ",
  "action-tag": "cLR caption-bold",
  "hotkey": "cLQ body-1",
  "footer-action": "cLe",
  "grow": "cLB",
  "footer-feedback-link": "cLE",
  "buttons-container": "cLd",
  "transfer-details-container": "cLI",
  "error-container": "cLt",
  "option-iban": "cLM",
  "actions": "cLP",
  "title": "cLl",
  "group-title": "cLX caption-bold",
  "beta-badge": "cLC",
  "hotkeys": "cLk",
  "supplier-invoices-dropzone": "cLJ",
  "success-icon": "cLs",
  "error-icon": "cLF",
  "file-items": "cLg",
  "file-item": "cLD",
  "uploader-file": "cLV",
  "transactions-table": "cLL",
  "transaction": "cLT",
  "prompt-example": "cLz"
};
