export default {
  "invoice-sidebar": "cHf",
  "body": "cHK",
  "box": "cHG",
  "box-header": "cHr",
  "footer": "cHb",
  "footer-button": "cHy",
  "sidebar-box": "cHU",
  "border-top": "cHj",
  "row": "cHW",
  "status-container": "cHu",
  "due-date-container": "cOS",
  "mark-as-btn": "cOc",
  "primary-actions": "cOq cHU",
  "primary-action": "cOZ body-1",
  "button-icon": "cOR"
};
