export default {
  "section-container": "coq",
  "disclaimer-section": "coZ",
  "trip-title": "coR caption-bold mb-16",
  "google-link-container": "coQ",
  "link-icon": "coe",
  "trip-details": "coB body-2",
  "trip-details-icon": "coE",
  "trip-details-title": "cod",
  "subtitle": "coI caption-bold mb-16",
  "disclaimer": "cot",
  "visualizer": "coM"
};
