export default {
  "payment-details": "cap",
  "label-payment-conditions": "cax",
  "dropdown-content": "caw",
  "dropdown-option": "caN",
  "activate-sdd-container": "cav",
  "activate-sdd-header": "cao",
  "selector-toggle-sdd": "can",
  "selector-toggle-sdd-subtitle": "cai",
  "selector-toggle-content": "caA",
  "selector-toggle": "caY",
  "new-badge": "cah",
  "new-badge-activation": "caf",
  "mt-16": "caK",
  "sdd-mandate-select-loading": "caG"
};
