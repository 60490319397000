export default {
  "header": "coP",
  "header-type": "col",
  "close": "coX",
  "close-icon": "coC",
  "header-infos": "cok",
  "title": "coJ body-1",
  "declined": "cos",
  "canceled": "coF",
  "approved": "cog",
  "pending": "coD",
  "date": "coV body-2",
  "mileage-icon": "coL",
  "distance-subtitle": "coT body-2",
  "link-icon": "coz",
  "container": "coa",
  "picto": "coH",
  "general": "coO",
  "amount": "com",
  "calculation-container": "cop",
  "counterparty": "cox body-2",
  "infobox": "cow"
};
