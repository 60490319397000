export default {
  "row": "cNs body-1",
  "active": "cNF",
  "cell": "cNg",
  "empty": "cND",
  "ellipsis": "cNV",
  "text-secondary": "cNL",
  "transfer-type": "cNT",
  "transfer-type__icon": "cNz",
  "quick-actions": "cNa",
  "amount": "cNH",
  "cell-approve": "cNO",
  "account-select": "cNm",
  "row-compact": "cNp",
  "cell-amount": "cNx",
  "cell-content-receipt": "cNw body-1",
  "cell-date": "cNN",
  "row-sidebar": "cNv",
  "hidden": "cNo",
  "animated": "cNn",
  "fadein-item": "cNi",
  "animated-cell": "cNA",
  "note": "cNY",
  "checkbox": "cNh"
};
