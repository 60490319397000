export default {
  "row": "cyR",
  "row-active": "cyQ",
  "avatar": "cye",
  "cell": "cyB",
  "cell-content": "cyE",
  "cell-counterparty": "cyd",
  "counterparty-name": "cyI",
  "cell-amount": "cyt",
  "status-avatar": "cyM",
  "counterparty": "cyP",
  "creditor-name": "cyl",
  "reference": "cyX",
  "counterparty-details": "cyC",
  "cell-quick-actions": "cyk",
  "quick-actions": "cyJ",
  "account-selector": "cys"
};
