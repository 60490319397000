export default {
  "sections": "crk",
  "actions": "crJ",
  "search-input": "crs",
  "no-upload": "crF",
  "new-link": "crg body-2",
  "between-links": "crD",
  "action-bar": "crV",
  "empty-state": "crL"
};
