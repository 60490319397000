export default {
  "modal-wrapper": "cmk",
  "spinner-container": "cmJ",
  "spinner": "cms",
  "container": "cmF",
  "tabs-item": "cmg",
  "form-container": "cmD",
  "fieldset": "cmV",
  "preview-container": "cmL",
  "switch-container": "cmT",
  "pdf-preview-container": "cmz",
  "form-content": "cma",
  "button-container-footer": "cmH"
};
