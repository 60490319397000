export default {
  "header": "cvP",
  "header-type": "cvl",
  "close": "cvX",
  "close-icon": "cvC",
  "header-infos": "cvk",
  "title": "cvJ body-1",
  "declined": "cvs",
  "canceled": "cvF",
  "approved": "cvg",
  "pending": "cvD",
  "date": "cvV body-2",
  "container": "cvL",
  "picto": "cvT",
  "picto-status": "cvz",
  "general": "cva",
  "amount": "cvH",
  "counterparty": "cvO body-2",
  "initiator": "cvm",
  "initiator-avatar": "cvp",
  "avatar-image": "cvx",
  "initiator-id": "cvw",
  "name": "cvN body-2",
  "role": "cvv caption-bold",
  "infobox": "cvo",
  "account-infobox": "cvn cvo",
  "beneficiary-warning": "cvi caption-bold",
  "dates-wrapper": "cvA",
  "dates": "cvY",
  "recurrence": "cvh caption-bold",
  "arrow": "cvf",
  "notify-checkbox": "cvK"
};
