export default {
  "form": "cOf",
  "container": "cOK",
  "section": "cOG",
  "footer": "cOr",
  "attachment": "cOb",
  "attachment-title": "cOy",
  "attachment-uploader-disabled": "cOU",
  "attachment-overlay": "cOj",
  "attachment-overlay-hidden": "cOW"
};
