export default {
  "wrapper": "cpo",
  "tabs": "cpn",
  "tabs-nav": "cpi",
  "tabs-panel": "cpA",
  "tabs-panel-wrapper": "cpY",
  "list-head": "cph",
  "list": "cpf",
  "footer": "cpK",
  "reward-amount": "cpG",
  "rewarded": "cpr",
  "registered": "cpb",
  "disclaimer": "cpy",
  "empty-state-wrapper": "cpU",
  "empty-state": "cpj",
  "illustration": "cpW",
  "spinner": "cpu",
  "total-earned": "cxS"
};
