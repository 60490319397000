export default {
  "selector": "czv",
  "pension-selector-content": "czo",
  "withholding-tax-selector-content": "czn",
  "withholding-tax-selector-content-row": "czi",
  "power-select-field": "czA",
  "rate-field": "czY",
  "stamp-revenue-field": "czh",
  "dropdown-option": "czf",
  "dropdown-content": "czK"
};
