export default {
  "container": "czD",
  "depositAmount": "czV",
  "flexRow": "czL",
  "depositInput": "czT",
  "dropdown": "czz",
  "itemRow": "cza",
  "line": "czH",
  "estimates-container": "czO",
  "estimates": "czm",
  "dt": "czp",
  "dd": "czx"
};
