export default {
  "container": "cmA",
  "share-panel": "cmY",
  "email-margin": "cmh",
  "message-margin": "cmf",
  "esignature-container": "cmK",
  "esignature-teaser-title": "cmG",
  "esignature-teaser-badge": "cmr",
  "esignature-teaser-body": "cmb",
  "button-container-footer": "cmy",
  "attachments": "cmU",
  "attachment-file": "cmj"
};
