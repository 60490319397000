export default {
  "gap-32": "cTb",
  "fieldset": "cTy",
  "date-picker": "cTU",
  "with-performance-date": "cTj",
  "mt-4": "cTW",
  "clients": "cTu",
  "dropdown-option": "czS",
  "dropdown-content": "czc",
  "select-client": "czq",
  "client-option": "czZ",
  "icon-tooltip": "czR",
  "payment-details": "czQ",
  "dates-container": "cze",
  "automatic-number-label": "czB",
  "label": "czE",
  "numbering-container": "czd",
  "fix-width": "czI",
  "customer-error": "czt",
  "amount-text": "czM",
  "header-text-field-container": "czP",
  "header-text-field": "czl",
  "header-text-field-close-button": "czX",
  "mt-16": "czC"
};
