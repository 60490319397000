export default {
  "content": "ciC",
  "balance": "cik",
  "duration": "ciJ",
  "green-text": "cis",
  "balance-subtitle": "ciF",
  "balance-duration": "cig",
  "footer": "ciD",
  "progress": "ciV",
  "progress-bar": "ciL",
  "progress-bar-text": "ciT",
  "progress-active": "ciz"
};
