export default {
  "wrapper": "cxl",
  "tabs": "cxX",
  "tabs-nav": "cxC",
  "tabs-panel-wrapper": "cxk",
  "list-head": "cxJ",
  "list": "cxs",
  "footer": "cxF",
  "reward-amount": "cxg",
  "earned": "cxD",
  "pending": "cxV",
  "empty-state-wrapper": "cxL",
  "empty-state": "cxT",
  "illustration": "cxz",
  "error-wrapper": "cxa",
  "error-illustration": "cxH",
  "error-description": "cxO",
  "spinner": "cxm",
  "total-earned": "cxp"
};
