export default {
  "row": "cyF",
  "cell": "cyg",
  "checkbox": "cyD",
  "icon": "cyV",
  "creditor-item-container": "cyL",
  "actions-item-container": "cyT",
  "due-date": "cyz",
  "amount": "cya",
  "align-right": "cyH"
};
