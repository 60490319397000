export default {
  "client-form": "cLi",
  "align-start": "cLA",
  "kind": "cLY",
  "name-fields": "cLh",
  "field": "cLf",
  "required-data-disclaimer": "cLK",
  "row": "cLG",
  "child": "cLr",
  "confirm-action": "cLb",
  "delete-action": "cLy",
  "legal-info": "cLU",
  "column-layout": "cLj"
};
