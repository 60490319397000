export default {
  "row": "cGF",
  "active": "cGg",
  "dot": "cGD",
  "red": "cGV",
  "green": "cGL",
  "yellow": "cGT",
  "status": "cGz",
  "align-right": "cGa",
  "animated": "cGH",
  "fadein-item": "cGO",
  "cell": "cGm body-2",
  "cell-content": "cGp",
  "amount": "cGx body-2",
  "subtitle": "cGw caption",
  "padding-left": "cGN",
  "padding-right": "cGv",
  "note": "cGo"
};
