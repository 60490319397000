export default {
  "header-cell": "cKi",
  "header-content": "cKA caption-bold",
  "active": "cKY",
  "empty": "cKh",
  "request-type": "cKf",
  "status": "cKK",
  "note": "cKG",
  "amount": "cKr"
};
