export default {
  "header-cell": "cmW",
  "empty": "cmu",
  "header-content": "cpS caption-bold",
  "active": "cpc",
  "align-right": "cpq",
  "col-8": "cpZ",
  "col-7": "cpR",
  "col-5": "cpQ",
  "col-4": "cpe"
};
