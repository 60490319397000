export default {
  "header-cell": "cnS",
  "empty": "cnc",
  "header-content": "cnq caption-bold",
  "active": "cnZ",
  "header-type": "cnR",
  "header-reason": "cnQ",
  "header-status": "cne",
  "header-amount": "cnB",
  "row-sidebar": "cnE",
  "hidden": "cnd",
  "animated": "cnI",
  "fadein-item": "cnt",
  "animated-cell": "cnM"
};
