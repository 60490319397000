export default {
  "mileage-validation": "cNq",
  "actions": "cNZ",
  "action-btn": "cNR",
  "action-btn-success": "cNQ",
  "action-btn-right": "cNe",
  "request-mileage-validation": "cNB",
  "account-selector": "cNE",
  "has-error": "cNd",
  "funds-disclaimer": "cNI",
  "account-options": "cNt",
  "account-balance": "cNM",
  "warnings": "cNP"
};
