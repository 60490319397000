export default {
  "modal": "cOu",
  "action-container": "cmS",
  "similar-label": "cmc",
  "info-icon": "cmq",
  "container": "cmZ",
  "subtitle": "cmR caption-bold",
  "error": "cmQ",
  "item-container": "cme"
};
