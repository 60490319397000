export default {
  "card": "cTO",
  "error": "cTm",
  "customer-info": "cTp",
  "edit-customer-tooltip": "cTx",
  "title": "cTw",
  "flex-row": "cTN",
  "dot-separator": "cTv",
  "flex-span": "cTo",
  "faq-link": "cTn",
  "tooltip-wrapper": "cTi",
  "badge-union": "cTA"
};
