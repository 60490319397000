/* import __COLOCATED_TEMPLATE__ from './client-filter.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface ClientFilterSignature {
  Element: null;
  Args: {
    selected: any;
    options: any[];
    onChange: (client: any) => void;
    search: (query: string) => Promise<any[]>;
  };
  Blocks: {
    default: [];
  };
}

const ClientFilter = templateOnlyComponent<ClientFilterSignature>();

export default ClientFilter;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'ReceivableInvoices::ClientFilter': typeof ClientFilter;
  }
}
