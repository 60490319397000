export default {
  "wrapper": "ciK",
  "request-header": "ciG",
  "request": "cir",
  "scheduled": "cib",
  "scheduled-label": "ciy",
  "note": "ciU",
  "note-label": "cij",
  "note-label-danger": "ciW",
  "note-content": "ciu",
  "status": "cAS",
  "transfer-amount": "cAc"
};
