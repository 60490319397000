export default {
  "container": "cOQ",
  "form-container": "cOe",
  "content": "cOB",
  "fields": "cOE",
  "input-settings": "cOd",
  "current-year": "cOI",
  "next-invoice-number": "cOt",
  "preview-container": "cOM",
  "actions": "cOP",
  "close-button": "cOl btn btn--icon-only btn--tertiary btn--large",
  "preview-image-container": "cOX",
  "preview-image": "cOC"
};
