export default {
  "iban": "crc",
  "checkbox": "crq",
  "toggle-content": "crZ",
  "beneficiary-name": "crR",
  "category": "crQ",
  "beneficiary-email": "cre",
  "beneficiary-form": "crB",
  "beneficiary-email-container": "crE"
};
