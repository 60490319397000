export default {
  "header": "chg",
  "header-type": "chD",
  "close": "chV",
  "close-icon": "chL",
  "header-infos": "chT",
  "title": "chz body-1",
  "declined": "cha",
  "canceled": "chH",
  "approved": "chO",
  "pending": "chm",
  "date": "chp body-2",
  "mileage-icon": "chx",
  "distance-subtitle": "chw body-2",
  "link-icon": "chN",
  "container": "chv",
  "picto": "cho",
  "general": "chn",
  "amount": "chi",
  "calculation-container": "chA",
  "counterparty": "chY body-2",
  "infobox": "chh"
};
