export default {
  "referral-invite-new": "cpL",
  "referral": "cpT",
  "center": "cpz",
  "illustration": "cpa",
  "share-referral": "cpH",
  "choice": "cpO",
  "line": "cpm",
  "email": "cpp",
  "email-input": "cpx",
  "input-field--error": "cpw",
  "error-message": "cpN",
  "external-link-icon": "cpv"
};
