export default {
  "numbering-container": "czG",
  "flex-row": "czr",
  "automatic-number-label": "czb",
  "icon-tooltip": "czy",
  "fix-width": "czU",
  "dates-container": "czj",
  "with-performance-date": "czW",
  "date-picker": "czu",
  "label": "caS",
  "header-text-field-container": "cac",
  "mt-16": "caq",
  "header-text-field": "caZ",
  "header-text-field-close-button": "caR",
  "deposit-toggle-container": "caQ"
};
