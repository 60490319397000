/* import __COLOCATED_TEMPLATE__ from './filters.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { equal } from 'macro-decorators';

import { FILTERS, TABS } from 'qonto/constants/receivable-invoice';

interface ReceivableInvoicesFiltersSignature {
  Args: {
    tab: string;
    selectedClient?: any;
    clients?: any[];
    selectClient?: (client: any) => void;
    handleClientSearch?: (query: string) => Promise<any[]>;
  };
  Blocks: {
    default: [];
  };
  Element: HTMLDivElement;
}

type FilterTabMap = Record<string, string[]>;

const FILTER_TAB_MAP: FilterTabMap = {
  [TABS.ALL_INVOICES]: [FILTERS.CLIENT, FILTERS.ISSUE_DATE, FILTERS.MULTI_STATUS],
  [TABS.DRAFTS]: [FILTERS.CLIENT, FILTERS.ISSUE_DATE],
  [TABS.UNPAID]: [FILTERS.CLIENT, FILTERS.ISSUE_DATE],
  [TABS.COMPLETED]: [FILTERS.CLIENT, FILTERS.ISSUE_DATE, FILTERS.SINGLE_STATUS],
};

export default class ReceivableInvoicesFilters extends Component<ReceivableInvoicesFiltersSignature> {
  @service declare abilities: any;
  @service declare intl: any;

  FILTERS = FILTERS;

  @equal('args.tab', TABS.ALL_INVOICES) isAllInvoicesTab!: boolean;
  @equal('args.tab', TABS.DRAFTS) isDraftsTab!: boolean;
  @equal('args.tab', TABS.UNPAID) unpaidTab!: boolean;
  @equal('args.tab', TABS.COMPLETED) isCompletedTab!: boolean;

  get filters() {
    return FILTER_TAB_MAP[this.args.tab] || [];
  }

  get showClientFilter() {
    const clients = this.args.clients ?? [];
    return clients.length > 0;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'ReceivableInvoices::Filters': typeof ReceivableInvoicesFilters;
  }
}
