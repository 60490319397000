export default {
  "header-cell": "cKV",
  "header-content": "cKL caption-bold",
  "active": "cKT",
  "empty": "cKz",
  "align-right": "cKa",
  "request-type": "cKH",
  "requester": "cKO",
  "amount": "cKm"
};
