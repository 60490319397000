/* import __COLOCATED_TEMPLATE__ from './invoice-pdf-preview.hbs'; */
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { PdfPreview } from '@repo/domain-kit/invoices';
// @ts-expect-error
import { variation } from 'ember-launch-darkly';

import { DEFAULT_LOCALE_PER_COUNTRY } from 'qonto/constants/receivable-invoice';
import type QuoteModel from 'qonto/models/quote.ts';
import type ReceivableInvoiceModel from 'qonto/models/receivable-invoice.ts';
import ReceivableInvoicesSettingsModel from 'qonto/models/receivable-invoices-settings.ts';

interface InvoicePdfPreviewSignature {
  // The arguments accepted by the component
  Args: {
    isQuote?: boolean;
    shouldUseV1?: boolean;
    useCustomerRecord?: boolean;
    settings: ReceivableInvoicesSettingsModel;
    invoice: ReceivableInvoiceModel | QuoteModel;
  };
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class InvoicePdfPreview extends Component<InvoicePdfPreviewSignature> {
  @service declare intl: Services['intl'];
  @service declare organizationManager: Services['organizationManager'];
  @service declare abilities: Services['abilities'];

  pdfPreviewComponent = PdfPreview;

  get customerLocale() {
    return this.args.invoice.locale && !this.args.useCustomerRecord
      ? this.args.invoice.locale
      : // @ts-expect-error
        this.args.invoice.get('customer.locale');
  }

  get userLocale() {
    return this.intl.primaryLocale || 'en';
  }

  get currencyIsDifferentThanEUR() {
    return this.args.invoice.currency !== 'EUR';
  }

  get isOrganizationCountryDifferentThanCustomerCountry() {
    let customerCountryCode = this.args.invoice.customer?.content?.countryCode;
    if (!customerCountryCode) return false;
    return this.organizationManager.organization.legalCountry !== customerCountryCode;
  }

  get showLanguageTooltip() {
    let orgaCountry = this.organizationManager.organization.legalCountry;
    return (
      // @ts-expect-error
      (this.args.showLanguageTooltip &&
        this.customerLocale &&
        // @ts-expect-error
        this.customerLocale !== DEFAULT_LOCALE_PER_COUNTRY[orgaCountry]) ||
      // @ts-expect-error
      (this.args.showLanguageTooltip && this.currencyIsDifferentThanEUR) ||
      // @ts-expect-error
      (this.args.showLanguageTooltip && this.isOrganizationCountryDifferentThanCustomerCountry)
    );
  }

  get showGermanTemplate() {
    return this.organizationManager.organization.legalCountry === 'DE';
  }

  get documentType() {
    return this.args.isQuote ? 'quote' : 'invoice';
  }

  get documentVariant() {
    return this.organizationManager.organization.legalCountry;
  }

  get settings() {
    return {
      transactionType: this.args.settings.transactionType,
      vatPaymentCondition: this.args.settings.vatPaymentCondition,
      discountConditions: this.args.settings.discountConditions,
      latePaymentPenalties: this.args.settings.latePaymentPenalties,
      legalFixedCompensation: this.args.settings.legalFixedCompensation,
      tcLinkUrl: this.args.settings.tcLinkUrl,
      tcLinkText: this.args.settings.tcLinkText,
      legalCapitalShare: this.args.settings.legalCapitalShare,
      commercialRegisterNumber: this.args.settings.commercialRegisterNumber,
      quoteTitle: this.args.settings.quoteTitle,
      invoiceTitle: this.args.settings.invoiceTitle,
      numberingMode: this.args.settings.numberingMode,
      quoteNextNumberFormatted: this.args.settings.quoteNextNumberFormatted,
      invoiceNextNumberFormatted: this.args.settings.invoiceNextNumberFormatted,
      tableColumns: this.args.settings.tableColumns?.map(col => ({
        id: col.id,
        label: col.label,
      })),
      colorText: this.args.settings.colorText,
      colorTextAlt: this.args.settings.colorTextAlt,
      colorTheme: this.args.settings.colorTheme,
    };
  }

  // Ember - React compatibility getter, forces Ember to create a new ref for the prop on mutation updates
  get document() {
    // @ts-expect-error
    let sections = this.args.invoice.sections?.map(section => ({
      id: section.id,
      title: section.title,
      description: section.description,
      totalExcludingVat: section.totalExcludingVat,
      // @ts-expect-error
      items: section.items.map(item => ({
        id: item.id,
        title: item.title,
        description: item.description,
        quantity: item.quantity,
        unit: item.unit,
        unitPrice: item.unitPrice,
        vatRate: item.vatRate,
        totalExcludingVat: item.totalExcludingVat,
        discount: item.discount,
        totalDiscount: item.totalDiscount,
        links: item.links,
        subTotal: item.subTotal,
        totalAmount: item.totalAmount,
        totalVat: item.totalVat,
        vatExemptionCode: item.vatExemptionCode,
      })),
    }));
    // @ts-expect-error
    let items = this.args.invoice.items?.map(item => ({
      id: item.id,
      title: item.title,
      description: item.description,
      quantity: item.quantity,
      unit: item.unit,
      unitPrice: item.unitPrice,
      vatRate: item.vatRate,
      totalExcludingVat: item.totalExcludingVat,
      discount: item.discount,
      totalDiscount: item.totalDiscount,
      links: item.links,
      subTotal: item.subTotal,
      totalAmount: item.totalAmount,
      totalVat: item.totalVat,
      vatExemptionCode: item.vatExemptionCode,
    }));

    return {
      number: this.args.invoice.number,
      status: this.args.invoice.status,
      currency: this.args.invoice.currency,
      // @ts-expect-error
      isDeposit: this.args.invoice.isDeposit,
      // @ts-expect-error
      reason: this.args.invoice.reason,
      // @ts-expect-error
      bic: this.args.invoice.bic,
      // @ts-expect-error
      iban: this.args.invoice.iban,
      reference: this.args.invoice.reference,
      beneficiaryName: this.args.invoice.beneficiaryName,
      contactEmail: this.args.invoice.contactEmail,
      issueDate: this.args.invoice.issueDate,
      // @ts-expect-error
      performanceStartDate: this.args.invoice.performanceStartDate,
      // @ts-expect-error
      performanceEndDate: this.args.invoice.performanceEndDate,
      // @ts-expect-error
      expiryDate: this.args.invoice.expiryDate,
      // @ts-expect-error
      dueDate: this.args.invoice.dueDate,
      // @ts-expect-error
      depositInvoiceNumber: this.args.invoice.depositInvoiceNumber,
      // @ts-expect-error
      depositPaymentDate: this.args.invoice.depositPaymentDate,
      // @ts-expect-error
      purchaseOrder: this.args.invoice.purchaseOrder,
      items,
      sections,
      discount: this.args.invoice.discount,
      totalExcludingVat: this.args.invoice.totalExcludingVat,
      totalDiscount: this.args.invoice.totalDiscount,
      discountedTotalExcludingVat: this.args.invoice.discountedTotalExcludingVat,
      welfareFundAmount: this.args.invoice.welfareFundAmount,
      displayEachVatSubtotals: this.args.invoice.displayEachVatSubtotals,
      vatSubtotals: this.args.invoice.vatSubtotals,
      totalVat: this.args.invoice.totalVat,
      withholdingTax: this.args.invoice.withholdingTax,
      withholdingTaxAmount: this.args.invoice.withholdingTaxAmount,
      stampDutyAmount: this.args.invoice.stampDutyAmount,
      totalAmount: this.args.invoice.totalAmount,
      // @ts-expect-error
      depositAmount: this.args.invoice.depositAmount,
      totalAmountDue: this.args.invoice.totalAmountDue,
      header: this.args.invoice.header,
      footer: this.args.invoice.footer,
      termsAndConditions: this.args.invoice.termsAndConditions,
      // @ts-expect-error
      pageCount: this.args.invoice.pageCount,
      locale: this.args.invoice.locale,
    };
  }

  get customer() {
    return this.args.useCustomerRecord
      ? this.args.invoice.customer.content
      : this.args.invoice.customerSnapshot;
  }

  get client() {
    const customer = this.args.useCustomerRecord
      ? this.args.invoice.customer.content
      : this.args.invoice.customerSnapshot;

    return customer
      ? {
          name: customer.name,
          email: customer.email,
          billingAddress: customer.billingAddress,
          deliveryAddress: customer.deliveryAddress,
          tinNumber: customer.tinNumber,
          vatNumber: customer.vatNumber,
          locale: customer.locale,
        }
      : undefined;
  }

  get merchant() {
    const address = this.organizationManager.organization.address;
    const organizationStreetAddress = address.firstLine
      ? address.firstLine
          .concat(address.secondLine ? ` ${address.secondLine}` : '')
          .concat(address.thirdLine ? ` ${address.thirdLine}` : '')
      : '';

    return {
      legalName: this.organizationManager.organization.legalName,
      legalNumber: this.organizationManager.organization.legalNumber,
      legalCountry: this.organizationManager.organization.legalCountry,
      address: {
        streetAddress: organizationStreetAddress,
        zipCode: address.zipcode,
        city: address.city,
        countryCode: address.country,
      },
      taxNumber: this.organizationManager.organization.taxNumber,
      vatNumber: this.organizationManager.organization.vatNumber,
      contactEmail: this.organizationManager.organization.contactEmail,
      districtCourt: this.organizationManager.organization.districtCourt,
      commercialRegisterNumber: this.organizationManager.organization.commercialRegisterNumber,
      companyLeadership: this.organizationManager.organization.companyLeadership,
      shortLegalForm: this.organizationManager.organization.shortLegalForm,
      locale: this.organizationManager.organization.locale,
    };
  }

  get relatedQuote() {
    // @ts-expect-error
    let quote = this.args.invoice.get?.('quote') ?? this.args.invoice.quote;
    return quote
      ? {
          number: quote.number,
        }
      : undefined;
  }

  get isV2Template() {
    return variation('feature--boolean-ar-advanced-customization') && !this.args.shouldUseV1;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'ReceivableInvoices::InvoicePdfPreview': typeof InvoicePdfPreview;
  }
}
