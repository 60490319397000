export default {
  "attachment-viewer": "cNj",
  "hide-sidebar": "cNW",
  "sidebar": "cNu",
  "header": "cvS",
  "header-top": "cvc",
  "header-icon": "cvq",
  "header-amount": "cvZ",
  "infobox": "cvR",
  "vat": "cvQ",
  "validation": "cve"
};
