export default {
  "subtitle": "ciR",
  "green-text": "ciQ",
  "balance-subtitle": "cie",
  "options": "ciB",
  "shadow": "ciE",
  "menu-item": "cid body-2",
  "close-account": "ciI",
  "footer": "cit",
  "progress": "ciM",
  "progress-bar": "ciP",
  "progress-bar-text": "cil",
  "progress-active": "ciX"
};
