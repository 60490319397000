export default {
  "row": "cnP",
  "active": "cnl",
  "dot": "cnX",
  "red": "cnC",
  "green": "cnk",
  "yellow": "cnJ",
  "status": "cns",
  "align-right": "cnF",
  "animated": "cng",
  "fadein-item": "cnD",
  "cell": "cnV body-2",
  "cell-content": "cnL",
  "amount": "cnT body-2",
  "subtitle": "cnz caption",
  "padding-left": "cna",
  "padding-right": "cnH",
  "note": "cnO"
};
