export default {
  "row": "cKb",
  "active": "cKy",
  "dot": "cKU",
  "red": "cKj",
  "green": "cKW",
  "yellow": "cKu",
  "status": "cGS",
  "align-right": "cGc",
  "animated": "cGq",
  "fadein-item": "cGZ",
  "cell": "cGR body-2",
  "cell-content": "cGQ",
  "amount": "cGe body-1",
  "subtitle": "cGB caption",
  "no-padding": "cGE"
};
