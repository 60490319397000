export default {
  "cell-content": "cAt",
  "icon": "cAM",
  "status_check-m": "cAP",
  "description": "cAl body-1",
  "from": "cAX body-2",
  "from--abort": "cAC",
  "description--abort": "cAk",
  "title": "cAJ body-2"
};
