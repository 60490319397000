export default {
  "sidebar": "cHx",
  "body": "cHw",
  "form": "cHN",
  "form-section": "cHv",
  "form-title": "cHo title2",
  "row": "cHn",
  "payment-details": "cHi",
  "col": "cHA",
  "footer": "cHY",
  "footer-button": "cHh"
};
