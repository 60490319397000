export default {
  "credit-note-sidebar": "cTZ",
  "body": "cTR",
  "box": "cTQ",
  "box-header": "cTe",
  "footer": "cTB",
  "footer-button": "cTE",
  "sidebar-box": "cTd",
  "border-top": "cTI",
  "row": "cTt",
  "related-invoice-title": "cTM caption-bold",
  "einvoice-rejected": "cTP",
  "related-documents": "cTl"
};
