export default {
  "header": "cwH",
  "header-type": "cwO",
  "close": "cwm",
  "close-icon": "cwp",
  "header-infos": "cwx",
  "title": "cww body-1",
  "declined": "cwN",
  "canceled": "cwv",
  "approved": "cwo",
  "pending": "cwn",
  "date": "cwi body-2",
  "mileage-icon": "cwA",
  "distance-subtitle": "cwY body-2",
  "link-icon": "cwh",
  "container": "cwf",
  "picto": "cwK",
  "general": "cwG",
  "amount": "cwr",
  "calculation-container": "cwb",
  "counterparty": "cwy body-2",
  "infobox": "cwU"
};
