export default {
  "row": "cGd",
  "active": "cGI",
  "animated": "cGt",
  "fadein-item": "cGM",
  "cell": "cGP body-2",
  "cell-content": "cGl",
  "subtitle": "cGX caption",
  "no-padding": "cGC",
  "note": "cGk",
  "cell-amount": "cGJ",
  "cell-content-amount": "cGs body-1"
};
