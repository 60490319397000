export default {
  "wrapper": "crT",
  "beneficiary": "crz",
  "name": "cra body-1",
  "beneficiary-email": "crH body-2",
  "dropdown-icon": "crO",
  "trusted": "crm",
  "account-number": "crp flex body-2",
  "account-icon": "crx",
  "m-chip": "crw",
  "dropdown": "crN body-2",
  "delete-option": "crv",
  "beneficiary-name": "cro"
};
