export default {
  "row": "cpd",
  "cell": "cpI",
  "greyed-out": "cpt",
  "struck-through": "cpM",
  "flex-cell": "cpP",
  "details-container": "cpl",
  "overdue": "cpX",
  "align-right": "cpC",
  "customer-name-container": "cpk",
  "icon": "cpJ",
  "warning": "cps",
  "with-separator": "cpF",
  "align-center": "cpg"
};
