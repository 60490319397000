export default {
  "header": "cYk",
  "header-type": "cYJ",
  "close": "cYs",
  "close-icon": "cYF",
  "header-infos": "cYg",
  "title": "cYD body-1",
  "declined": "cYV",
  "canceled": "cYL",
  "approved": "cYT",
  "pending": "cYz",
  "date": "cYa body-2",
  "container": "cYH",
  "picto": "cYO",
  "picto-status": "cYm",
  "general": "cYp",
  "amount": "cYx",
  "counterparty": "cYw body-2",
  "initiator": "cYN",
  "initiator-avatar": "cYv",
  "avatar-image": "cYo",
  "initiator-id": "cYn",
  "name": "cYi body-2",
  "role": "cYA caption-bold",
  "infobox": "cYY",
  "account-infobox": "cYh cYY",
  "beneficiary-warning": "cYf caption-bold",
  "dates-wrapper": "cYK",
  "dates": "cYG",
  "recurrence": "cYr caption-bold",
  "arrow": "cYb",
  "notify-checkbox": "cYy"
};
