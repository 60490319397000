export default {
  "header": "cft",
  "header-type": "cfM",
  "close": "cfP",
  "close-icon": "cfl",
  "header-infos": "cfX",
  "title": "cfC body-1",
  "declined": "cfk",
  "canceled": "cfJ",
  "approved": "cfs",
  "date": "cfF body-2",
  "container": "cfg",
  "picto": "cfD",
  "request": "cfV",
  "picto-status": "cfL",
  "check": "cfT",
  "stop": "cfz",
  "warning": "cfa",
  "general": "cfH",
  "amount": "cfO",
  "counterparty": "cfm body-2",
  "initiator": "cfp",
  "initiator-avatar": "cfx",
  "avatar-image": "cfw",
  "initiator-id": "cfN",
  "name": "cfv body-2",
  "role": "cfo caption-bold",
  "infobox": "cfn",
  "account-infobox": "cfi cfn",
  "beneficiary-warning": "cfA caption-bold",
  "dates-wrapper": "cfY",
  "dates": "cfh",
  "recurrence": "cff caption-bold",
  "arrow": "cfK",
  "notify-checkbox": "cfG"
};
