export default {
  "mileage-validation": "coA",
  "actions": "coY",
  "action-btn": "coh",
  "action-btn-right": "cof",
  "request-mileage-validation": "coK",
  "account-selector": "coG",
  "has-error": "cor",
  "funds-disclaimer": "cob",
  "account-options": "coy",
  "account-balance": "coU",
  "warnings": "coj"
};
