export default {
  "card-details-container": "cAn",
  "title": "cAi caption-bold mb-16",
  "section": "cAA",
  "divider": "cAY",
  "top-divider": "cAh",
  "content": "cAf body-2",
  "description": "cAK",
  "justify-end": "cAG",
  "icon": "cAr ml-8"
};
